import Apl from "./Apl";

const Mainpage = () => {
  return (
    <div>
      <div className="container register">
        <div className="row">
          <div className="col-md-3 register-left">
            <img src="/apl-LOGO.png" alt="" />
            <h3>Welcome!</h3>
            <p>
              Please enter the following details to register for <br /> APL-2025
            </p>
          </div>
          <div className="col-md-9 register-right">
            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="home"
                role="tabpanel"
                aria-labelledby="home-tab"
              >
                <h3 className="register-heading">
                  Acharya Premier League - 10{" "} 
                  {/* Registrations Closed ! <br /> 
                  Visit us next year*/}
                </h3>
                  <Apl />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mainpage;
