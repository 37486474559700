import axios from "axios";
import { ToastsStore } from "react-toasts";

export const registerVolunteer = async (formdata, setLoading) => {
  try {
    setLoading(true);
    const response = await axios.post(
      process.env.REACT_APP_API_URL + `/volunteer/register`,
      { ...formdata, type: "student" }
    );
    document.getElementById("vForm").reset();
    if (response.data.success) {
      setLoading(false);
      document.getElementById("note-success").style.display = "block";
      document.getElementById("captcha").style.display = "none";
      document.getElementById("register-btn").style.display = "none";
      ToastsStore.info(response.data.message);
    } else {
      setLoading(false);
      ToastsStore.info(response.data.message);
    }
  } catch (error) {
     document.getElementById("vForm").reset();
    setLoading(false);
    ToastsStore.info(error.response.data.message);
  }
};

export const registerEvents = async (formdata, setLoading, ref) => {
  try {
    setLoading(true);
    const response = await axios.post(
      process.env.REACT_APP_API_URL + `/event/register`,
      { ...formdata, type: "student" }
    );
     document.getElementById("vForm").reset();
    if (response.data.success) {
      setLoading(false);
      if (ref.current) {
        ref.current.reset();
      }
      document.getElementById("note-success").style.display = "block";
      document.getElementById("captcha").style.display = "none";
      document.getElementById("register-btn").style.display = "none";
      ToastsStore.info(response.data.message);
    } else {
      setLoading(false);
      if (ref.current) {
        ref.current.reset();
      }
      ToastsStore.info(response.data.message);
    }
  } catch (error) {
     document.getElementById("vForm").reset();
    setLoading(false);
    if (ref.current) {
      ref.current.reset();
    }
    ToastsStore.info(error.response.data.message);
  }
};
