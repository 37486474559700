import { VolunteerRegistration } from "./VolunteerRegistration";

const VolunteerMain = () => {
  return (
    <div>
      <div className="container register">
        <div className="row">
          <div className="col-md-3 volunteer-register-left">
            <div className="register-left-logo">
              <img src="/HabbaLogo.png" alt="" />
              <h4>2025</h4>
            </div>
            <h3>Welcome!</h3>
            <p>
              Please enter the following details to register for <br />
              Acharya Habba-2025
            </p>
          </div>
          <div className="col-md-9 register-right">
            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="home"
                role="tabpanel"
                aria-labelledby="home-tab"
              >
                <h3 className="register-heading">
                  Habba 2025 Volunteer Registration{" "}
                  {/* Registrations Closed ! <br /> Visit us next year   */}
                </h3>
                <VolunteerRegistration />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VolunteerMain;
