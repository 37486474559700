import React, { useState } from "react";
import { ToastsContainer, ToastsStore } from "react-toasts";
import LoadingSpinner from "../components/Spinner";
import { colleges } from "../resources/colleges";
import { renderDepartments } from "../resources/departments";
import { preferences } from "../resources/preferences";
import { years } from "../resources/year";
import { registerVolunteer } from "../api/registerVolunteer";
import ReCAPTCHA from "react-google-recaptcha";
import { useRef } from "react";

export const VolunteerRegistration = () => {
  const ref = useRef(null);
  const [verified, setVerified] = useState(true);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    registerVolunteer(formData, setLoading, ref);
  };

  const [formData, setFormData] = useState({ college: null });

  const handleInput = (e) => {
    if (e.target.name === "college") {
      setFormData({ ...formData, department: null });
      var mySelect = document.getElementById("department");
      mySelect.selectedIndex = 0;
    }
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <div>
      <form id="vForm" onSubmit={(e) => handleSubmit(e)}>
        <div className="row register-form">
          <div className="col-md-6">
            <div className="form-group">
              <input
                className="form-control"
                type="email"
                required
                placeholder="Email"
                name="email"
                autoComplete="off"
                onChange={(e) => handleInput(e)}
              />
              <div style={{ fontSize: "10px" }}>Acharya Email-ID Only!</div>
            </div>

            <div className="form-group">
              <input
                className="form-control"
                type="text"
                placeholder="Name"
                name="name"
                onChange={(e) => handleInput(e)}
              />
              <div style={{ fontSize: "10px" }}>Name as per ID-Card</div>
            </div>

            <div className="form-group">
              <input
                className="form-control"
                type="text"
                placeholder="AUID"
                name="auid"
                onChange={(e) => handleInput(e)}
              />
            </div>

            <div className="form-group">
              <select
                placeholder="College"
                className="form-control"
                required
                name="college"
                onChange={(e) => handleInput(e)}
              >
                <option selected defaultValue="">
                  Choose Your Institute
                </option>
                {colleges.map((college, index) => {
                  return (
                    <option
                      key={index}
                      value={`${college.value} - ${college.college}`}
                    >
                      {college.college}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className="form-group">
              <select
                placeholder="department"
                className="form-control"
                id="department"
                required
                name="department"
                onChange={(e) => handleInput(e)}
              >
                <option selected defaultValue="">
                  Choose Your Department
                </option>
                {formData.college &&
                  renderDepartments(formData.college).map(
                    (department, index) => {
                      return (
                        <option
                          key={index}
                          value={`${department.value} - ${department.department}`}
                        >
                          {department.department}
                        </option>
                      );
                    }
                  )}
              </select>
            </div>

            <div className="form-group">
              <select
                placeholder="Preference 1"
                className="form-control"
                required
                name="pref1"
                onChange={(e) => handleInput(e)}
              >
                <option selected disabled defaultValue="">
                  Team Preference 1
                </option>
                {preferences.map((preference, index) => {
                  return (
                    <option key={index} value={preference.preference}>
                      {preference.preference}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className="form-group">
              <select
                placeholder="Preference 2"
                className="form-control"
                required
                name="pref2"
                onChange={(e) => handleInput(e)}
              >
                <option selected disabled value="">
                  Team Preference 2
                </option>
                {preferences.map((preference, index) => {
                  return (
                    <option
                      key={index}
                      disabled={formData.pref1 === preference.preference}
                      value={preference.preference}
                    >
                      {preference.preference}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className="form-group">
              <select
                placeholder=""
                className="form-control"
                required
                name="year"
                onChange={(e) => handleInput(e)}
              >
                <option selected defaultValue="">
                  Year Of Study
                </option>
                {years.map((year, index) => {
                  return (
                    <option key={index} value={year.year}>
                      {year.year}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group">
              <input
                onFocus={(e) => {
                  e.currentTarget.type = "date";
                  e.currentTarget.focus();
                }}
                onBlur={(e) => {
                  e.currentTarget.type = "text";
                  e.currentTarget.blur();
                }}
                className="form-control"
                type="text"
                placeholder="Date of Birth"
                name="dob"
                onChange={(e) => handleInput(e)}
              />
            </div>

            <div className="form-group">
              <input
                className="form-control"
                type="text"
                pattern="[0-9]{10}"
                maxLength={10}
                placeholder="WhatsApp Number"
                name="whatsapp"
                onChange={(e) => handleInput(e)}
              />
            </div>

            <div className="form-group">
              <input
                maxLength={10}
                className="form-control"
                type="text"
                pattern="[0-9]{10}"
                placeholder="Contact Number"
                name="call"
                onChange={(e) => handleInput(e)}
              />
            </div>

            <div className="form-group">
              <input
                className="form-control"
                type="text"
                placeholder="Do you have any past experience in Habba?"
                name="exp"
                minLength={5}
                onChange={(e) => handleInput(e)}
              />
            </div>

            <div className="form-group">
              <textarea
                rows="6"
                className="form-control"
                type="text"
                placeholder="Why Do you Want to join Habba Team?"
                name="reason"
                minLength={5}
                onChange={(e) => handleInput(e)}
              />
            </div>

            <div onChange={(e) => handleInput(e)}>
              <input type="radio" value="Male" name="gender" /> Male &nbsp;
              <input type="radio" value="Female" name="gender" /> Female
            </div>
          </div>
          {
            <div id="captcha" className="captcha-holder">
              <ReCAPTCHA
                ref={ref}
                sitekey={process.env.REACT_APP_RECAPTCHA}
                onChange={(value) => {
                  setFormData({ ...formData, token: value });
                  setVerified(true);
                }}
              />
            </div>
          }
          {verified && (
            <div id="register-btn" style={{ margin: "15px auto 0 auto" }}>
              {loading ? (
                <LoadingSpinner />
              ) : (
                <button
                  type="submit"
                  style={{
                    background: "#0DABF1",
                    color: "white",
                    borderRadius: "20px",
                    fontWeight: 500,
                    padding: "3px 22px",
                    border: "none",
                    cursor: "pointer",
                  }}
                >
                  Register
                </button>
              )}
            </div>
          )}
          <div id="note-success" className="image-size caution note-email">
            Registration Successful, Check Mail
          </div>
          <div className="image-size caution note-email">
            For any Queries Contact Us on :{" "}
            <a href="mailto:acharyahabba@acharya.ac.in">
              acharyahabba@acharya.ac.in
            </a>
          </div>
        </div>
      </form>
      <ToastsContainer store={ToastsStore} position="top_center" />
    </div>
  );
};
