export const preferences = [
  { value: "Music", preference: "Music" },
  { value: "Dance", preference: "Dance" },
  { value: "Gaming", preference: "Gaming" },
  { value: "Literary&Dramatics", preference: "Literary And Dramatics" },
  { value: "LifeStyle", preference: "Life Style" },
  { value: "Intracolleiate", preference: "Intra-College" },
  { value: "Mis", preference: "Miscellaneous" },
  { value: "Mestori", preference: "Mestori" },
  { value: "Tfest", preference: "Technical Fest" },
  { value: "Photography", preference: "Photography" },
  { value: "Adeventure", preference: "Adventure" },
  { value: "AKV", preference: "Acharya Kannada Vedike" },
  { value: "Sports", preference: "Sports" },
  { value: "VideoEditing", preference: "Video Editing" },
  { value: "DefenceSquad", preference: "Defence Squad" },
  { value: "Content", preference: "Content" },
  { value: "Decoration", preference: "Decoration" },
  { value: "Fine Arts", preference: "Fine Arts" },
  { value: "Design and Digital Art", preference: "Design and Digital Art" },
  { value: "Marketing", preference: "Marketing" },
  { value: "Digital Marketing", preference: "Digital Marketing" },
  { value: "Photo Editors", preference: "Photo Editor" },
  { value: "Graphic Designer", preference: "Graphic Designer" },
];
